import type { MutationTree } from 'vuex';
import type { RootState } from './types';
import type { ThemeConfig } from '@/plugins/config';
import { getProductSlug, isActiveDact } from '@/helpers';

type RootMutationsTree = MutationTree<RootState>;

export const mutations: RootMutationsTree = {
  setOnline(state: RootState, payload: boolean) {
    state.isOnline = payload;
  },

  setCurrentTheme(state: RootState, theme: ThemeConfig) {
    state.theme = theme;
  },

  setProduct(state: RootState, payload: string) {
    state.product = getProductSlug(payload);

    let prefix = '';
    if (payload === 'sat' || payload === 'tsi') {
      prefix = payload + '-';
    }

    if (payload === 'ksa' || payload === 'fsa') {
      prefix = 'courses-';
    }

    state.productPrefix = prefix;
  },

  setLoading(state: RootState, payload: boolean) {
    state.isLoading = payload;
  },

  setReplaceAppHeader(state: RootState, payload: boolean) {
    state.replaceAppHeader = isActiveDact() && payload;
  },
};
