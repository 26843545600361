import type { GetterTree } from 'vuex';
import type { RootState } from '@/store/types';
import type { ThemeConfig } from '@/plugins/config';
import { getProductSlug } from '@/helpers';

type RootGetterTree = GetterTree<RootState, RootState>;

export const getters: RootGetterTree = {
  theme(state: RootState): ThemeConfig {
    return state.theme;
  },

  isOnline(state: RootState): boolean {
    return state.isOnline;
  },

  productPrefix(state: RootState): string {
    return state.productPrefix;
  },

  activeProduct(state: RootState): string {
    return getProductSlug(state.product);
  },

  isEprepProduct(state: RootState): boolean {
    const eprepProducts = ['dsat', 'dpsat'];
    return eprepProducts.includes(state.product.toLowerCase());
  },

  isLoading(state: RootState): boolean {
    return state.isLoading;
  },

  replaceAppHeader(state: RootState): boolean {
    return !!state.replaceAppHeader;
  },
};
