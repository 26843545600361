import type { BasicsSubjects, IBasicsAssessmentPeriods, BasicsExams, IUSection } from '@/store/types';
import type { IContentPassage, IContentQuestion } from '@/store/modules/content/types';

export interface ILockSection {
  usection_id: number;
  is_locked: 1 | 0;
  locked_by: number;
}

export interface IAssessmentSubject {
  subject: string;
  testNumber: number;
  sectionNumber?: number;
  grade?: number;
  courseType?: string;
}

export interface IAssessmentState {
  sections: IUSection[] | [];
  section: IUSection | null;
  feedback: IAssessmentFeedback | null;
  feedbackStandards: IAssessmentFeedbackStandard[] | null;
  questionLessons: IQuestionLessons[] | null;
  answeredQuestions: IAssessmentReviewQuestion[] | null;
  pctReview: IAssessmentPctReview | null;
  performance: IPerformance | null;
  assignments: IAssignment[] | [];
}

export type IPerformanceResultItem = { subject: 'elar' | 'math' | 'Total'; performance: number };
export type IPerformanceResult = IPerformanceResultItem[];
export type IPerformanceItem = { subject: keyof typeof PerformanceLabels; label: string; performance: number | null };
export type IPerformance = IPerformanceItem[];

export type IAssignment = {
  id: string;
  uid: number;
  orgid: number;
  title: string;
  annotations?: string | null;
  quiz_id: string;
  quiz_title: string;
  subject: string;
  starts_at: string;
  ends_at: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  session?: IUSection | null;
  is_locked?: 1 | 0 | null;
  is_past_due?: 1 | 0 | null;
  is_deleted?: 1 | 0 | null;
};

export enum PerformanceLabels {
  math = 'Math',
  elar = 'ELAR',
  overall = 'Overall',
}

export interface IReactHomeRoom {
  sections: IUSection[];
  performance: IPerformanceResult | null;
  assignments: IAssignment[] | null;
}

export interface IAssessmentPctReview {
  pct_reviewed_all: number;
  pct_reviewed_recommended: number;
}

export interface NavigationMarks {
  isFlagged: boolean;
  isAnswered: boolean;
  isDirty: boolean;
  num: number;
}

export interface IQuestionNavigation {
  display?: number;
  total?: number;
  value?: number;
  marks?: NavigationMarks[];
  disabled?: boolean;
}

export interface IAssessmentRemedialId {
  remedial_id: number;
}

export interface TimeTrack {
  spentQuestionTime: number;
  remainingSectionTime: number;
}

export interface IQuestionLessons {
  id: number;
  name: string;
  subject: string;
  video: string;
  video_spanish?: string;
  is_reviewed: 1 | 0 | null;
}

export interface IAssessmentFeedback {
  i: number;
  c: number;
  s: number;
  raw: number;
  projected: number;
  range: string;
  prctl: number;
  feedback_i: number;
  feedback_c: number;
  feedback_s: number;
  feedback_raw: number;
  feedback_projected: number;
  feedback_range: string;
  feedback_prctl: number;
  fb_i: number;
  fb_c: number;
  fb_s: number;

  standard_id?: number;
  standard_key?: string;
  standard_name?: string;
  feedback?: { c: number; i: number; fb_c: number; fb_i: number };
}

export interface IAssessmentFeedbackStandard {
  standard_id: number;
  standard_key: string;
  standard_name: string;
  feedback: { c: number; i: number; fb_c: number; fb_i: number };
}

export interface IAssessmentFeedbackPct {
  feedback_pct_i: number;
  feedback_pct_c: number;
}

export interface IAssessmentFeedbackUpdate {
  feedback_i: number;
  feedback_c: number;
  feedback_s: number;
  feedback_raw: number;
  feedback_score: number;
  feedback_prctl: number;
}

export interface IAssessmentFbICSTotals {
  i: number;
  c: number;
  s: number;
}

export interface IAssessmentAnswerFeedback {
  ics: string | null;
  fb_ics: string | null;
}

export interface IAssessmentParams {
  subject: BasicsSubjects;
  test_num: string;
  grade: number;
  periods: IBasicsAssessmentPeriods[];
}

export interface IAssessmentProjectedScores {
  min: number;
  max: number;
  feedback_min: number;
  feedback_max: number;
}

export interface IAssessmentSectionData {
  id: number;
  exam: BasicsExams;
}

export interface IAssessmentReviewQuestion {
  id: number;
  num: number;
  usection_id: number;
  question_id: number;
  answer: string;
  time: string;
  ics: string;
  fb_ics: string | null;
  is_reviewed: 1 | 0;
  correct: string;
  correct_fillin?: string;
  difficulty: string;
  video: string;
  video_spanish?: string;
  full: IContentQuestion | null;
  explanation?: IQuestionExplanation | null;
  standard_ids?: number[];
}

// Question Explanation
export interface IQuestionExplanation {
  introduction: string;
  explanation: string;
  steps: IQuestionExplanationStep[];
}

export interface IQuestionExplanationStep {
  content: string;
  correct_option?: string;
  options?: IQuestionExplanationStepOptions;
  prompt?: string;
  student_answer?: string | null; // temporary
}

export type IQuestionExplanationStepOptions = {
  [key: string]: string;
};

export interface ITools {
  [key: string]: boolean;
  magnifier: boolean;
  highlighter: boolean;
  lineReader: boolean;
  answerEliminator: boolean;
  answerMasking: boolean;
  calculator: boolean;
}

export interface IPracticeSection {
  id: boolean;
  test_num: string;
  num: number;
  title: string;
  exam: string;
  edition: string;
  subject: string;
  ct: number;
  time: number;
}

export interface ISaveAnswerResponseData {
  status: 'ok' | 'extended' | 'error';
  questions: IContentQuestion[] | null;
  passages: IContentPassage[] | null;
}

export enum WidthType {
  Divided = 'divided',
  Centered = 'centered',
  Full = 'full',
}
