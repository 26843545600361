export interface IExamRoomState {
  //CERT
  windows: IExamRoomWindow[] | null;
  averages: IExamRoomAvg | null;
  // DSAT/PSAT
  assessments: ITests;
  assessments_diagnostic: ITests;
  // Both
  superscore: IExamRoomSuperscore | null;
}

export interface ITest {
  test_num: string;
  sections: ISection[];
  // FIXME:  Replace type 'any' for the correct type
  summary: any;
}

export type ITests = ITest[] | [];

export interface IExamRoomWindow {
  assessment: 'fall' | 'spring' | 'winter';
  // FIXME:  Replace type 'any' for the correct type
  sections: any[];
  // FIXME:  Replace type 'any' for the correct type
  summary: any;
}

export type IExamRoomWindows = IExamRoomWindow[] | null;

export interface IExamRoomAvg {
  english: number;
  english_feedback: number;
  math: number;
  math_feedback: number;
  reading: number;
  reading_feedback: number;
  science: number;
  science_feedback: number;
  composite: number;
  composite_feedback: number;
  target: number;
}

export interface IExamRoomSuperscore {
  english: number;
  english_feedback: number;
  math: number;
  math_feedback: number;
  reading: number;
  reading_feedback: number;
  science: number;
  science_feedback: number;
  composite: number;
  composite_feedback: number;
  target: number | null;
}

export interface ISection {
  section_id: number;
  test_num: string;
  num: number;
  subject: EprepSubjects;
  time: string;
  unqid: string;
  pct_review: number;
  pct_vid_all: number;
  score: number;
  feedback_score: number;
  group_score: number;
  feedback_group_score: number;
  time_remain: string;
  status: string;
  ct: number;
  i: number;
  c: number;
  feedback_i?: number;
  feedback_c?: number;
  s: number;
  is_completed: 1 | 0;
  is_started?: 1 | 0 | null;
  is_reviewed?: 1 | 0 | null;
  dt_start?: string | null;
  exam: string;
  created_at: string;
  updated_at: string;
  last_activity?: string;
  is_late: 1 | 0;
  is_blocked: 1 | 0;
}

export enum BasicsSatSubjectsNicknames {
  reading = 'reading',
  writing = 'writing',
  math3 = 'math3',
  math4 = 'math4',
  composite = 'composite',
}

export enum BasicsSatSubjectsLabels {
  reading = 'Reading',
  writing = 'Writing',
  math3 = 'Math (No-Calc)',
  math4 = 'Math (Calc)',
}

export enum BasicsActSubjectsLabels {
  english = 'English',
  math = 'Math',
  reading = 'Reading',
  science = 'Science',
}

export enum BasicsDactButtonLabels {
  english = 'English Section',
  math = 'Math Section',
  reading = 'Reading Section',
  science = 'Science Section',
}

export enum BasicDsatSubjectsLabels {
  math1 = 'Mathematics Module 1',
  math2 = 'Mathematics Module 2',
  rw1 = 'Reading & Writing Module 1',
  rw2 = 'Reading & Writing Module 2',
}

export enum BasicDsatSubjectsLabelsMerged {
  math = 'Mathematics',
  rw = 'Reading & Writing',
}

export enum EprepSubjects {
  'RW' = 'rw',
  'MATH' = 'math',
}

export enum PassageCss {
  DACT_PASSAGE_HIGHLIGHT = 'dact-passage-highlight',
  DACT_CURRENT_QUESTION = 'dact-current-question',
}
