import {
  BasicDsatSubjectsLabels,
  BasicDsatSubjectsLabelsMerged,
  BasicsActSubjectsLabels,
  BasicsDactButtonLabels,
  BasicsSatSubjectsLabels,
  BasicsSatSubjectsNicknames,
} from '@/store/modules/examroom/types';
import api from '@/ApiCaller';
import store from '@/store';
import router from '@/router';
import type { IContentAsset, IContentAssets } from '@/store/modules/content/types';
import type { IProduct, IUser } from '@/store/modules/auth/types';
import { BasicProducts, type IBasicProducts } from '@/store/types';
import { DateTime } from 'luxon';

export const getActiveProduct = (): IBasicProducts => {
  return store.getters.activeProduct;
};

export const changeToEprepTitle = (testNum: string | number): string => `Test ${testNum}`;

export const changeToTitle = (text: string): string => {
  const first = text.slice(0, 1);
  const leftover = text.slice(1);
  return `${first.toUpperCase()}${leftover}`;
};

export const changeToGrade12Title = (period: number | string): string => {
  return `Assessment ${period}`;
};

export const translateDifficulty = (char: string) => {
  switch (char.toLowerCase()) {
    default:
    case 'e':
      return 'Easy';
    case 'm':
      return 'Medium';
    case 'h':
      return 'Hard';
  }
};

export const translateWindowToTestNum = (window: string): number => {
  switch (window.toLowerCase()) {
    default:
    case 'fall':
      return 1;
    case 'winter':
      return 2;
    case 'spring':
      return 3;
  }
};

export const translateTestNumToWindow = (testNum: number): 'fall' | 'winter' | 'spring' => {
  switch (testNum) {
    default:
    case 1:
      return 'fall';
    case 2:
      return 'winter';
    case 3:
      return 'spring';
  }
};

//SCROLL TOP HELPER
type EaseInOutQuadOptions = {
  currentTime: number;
  start: number;
  change: number;
  duration: number;
};

const easeInOutQuad = ({ currentTime, start, change, duration }: EaseInOutQuadOptions) => {
  let newCurrentTime = currentTime;
  newCurrentTime /= duration / 2;

  if (newCurrentTime < 1) {
    return (change / 2) * newCurrentTime * newCurrentTime + start;
  }

  newCurrentTime -= 1;
  return (-change / 2) * (newCurrentTime * (newCurrentTime - 2) - 1) + start;
};

type SmoothScrollOptions = {
  duration: number;
  element: HTMLElement;
  to: number;
};

export default function smoothScroll({ duration, element, to }: SmoothScrollOptions) {
  if (element) {
    const start = element.scrollTop;
    const change = to - start;
    const startDate = new Date().getTime();

    const animateScroll = () => {
      const currentDate = new Date().getTime();
      const currentTime = currentDate - startDate;
      element.scrollTop = easeInOutQuad({
        currentTime,
        start,
        change,
        duration,
      });

      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      } else {
        element.scrollTop = to;
      }
    };
    animateScroll();
  }
}

//END SCROLL TOP HELPER

export function subjectFullName(subject: string, product?: string) {
  if (!subject) return '';

  if (subject.toUpperCase() === 'ELAR') {
    return 'ELAR';
  }

  if (isDsatOrDpsat(product ?? '') && subject.toUpperCase() === 'MATH') {
    return 'Mathematics';
  }

  if (subject.toUpperCase() === 'RW') {
    return 'Reading & Writing';
  }

  return subject.charAt(0).toUpperCase() + subject.slice(1).toLowerCase();
}

export function titleToIndex(subject: string) {
  if (subject === 'rw') {
    return 'RW';
  }

  return subject?.charAt(0).toUpperCase() + subject?.slice(1).toLowerCase() || '';
}

export function subjectShortName(subject: string, product?: string) {
  if (!isDsatOrDpsat(product ?? '') && subject) {
    return subject.charAt(0).toUpperCase() + subject.slice(1).toLowerCase();
  }

  if (subject) {
    if (subject.toUpperCase() === 'MATH') {
      return 'Math';
    }

    return 'R&W';
  }

  return '';
}

export function quizzesSubjects(product: string) {
  switch (product) {
    case 'dsat':
    case 'dpsat':
      return {
        rw: 'Reading & Writing',
        math: 'Mathematics',
      };
    case 'sat':
      return {
        reading: 'Reading',
        writing: 'Writing',
        math: 'Math',
      };
    case 'act':
    case 'dact':
    default:
      return {
        english: 'English',
        math: 'Math',
        reading: 'Reading',
        science: 'Science',
      };
  }
}

export function productSubjects(product: string) {
  switch (product) {
    case 'dsat':
    case 'dpsat':
      return {
        rw: 'Reading & Writing',
        math: 'Mathematics',
      };
    case 'sat':
      return {
        reading: 'Reading',
        writing: 'Writing',
        math: 'Math',
      };
    case 'act':
    case 'dact':
    default:
      return {
        english: 'English',
        math: 'Math',
        reading: 'Reading',
        science: 'Science',
      };
  }
}

export function isDsatOrDpsat(product?: string) {
  return product?.toLowerCase() === 'dsat' || product?.toLowerCase() === 'dpsat';
}

export function isActiveDsatOrDpsat() {
  return isDsatOrDpsat(getActiveProduct());
}

export function isDact(product?: string) {
  return product?.toLowerCase() === 'dact';
}

export function isActiveDact() {
  return isDact(getActiveProduct());
}

export function isEsa(product?: string) {
  return isDsatOrDpsat(product) || isDact(product);
}

export function isActiveEsa() {
  return isEsa(getActiveProduct());
}

export function isActiveActOrDact() {
  return getActiveProduct() === 'act' || getActiveProduct() === 'dact';
}

export function removeDiagWhenFull(products?: IProduct[]) {
  if (!products) {
    return;
  }

  const idx: Record<string, string> = { '6': '206', '7': '207', '13': '213' };
  const ret = JSON.parse(JSON.stringify(products)) as IProduct[];

  for (const pid in idx) {
    const full = ret.find((p) => +p.product_id === +pid);
    const diag = ret.find((p) => +p.product_id === +idx[pid]);

    if (full && diag) {
      ret.splice(ret.indexOf(diag), 1);
    }
  }

  return ret;
}

export function activeExamRoomHeaders() {
  return examRoomSubjectsHeaders(getActiveProduct());
}

export function examRoomSubjectsHeaders(product: string) {
  switch (product) {
    case 'dsat':
    case 'dpsat':
      return {
        rw: { subject: 'Reading & Writing', modules: [1, 2], colspan: 3 },
        math: { subject: 'Mathematics', modules: [1, 2], colspan: 3 },
      };
    case 'sat':
      return {
        reading: 'Reading',
        writing: 'Writing',
        rw_average: 'R&W AVG',
        math: 'Math',
        math_average: 'Math AVG',
      };
    case 'dact':
    case 'act':
    default:
      return {
        english: { subject: 'English', colspan: 3 },
        math: { subject: 'Math', colspan: 3 },
        reading: { subject: 'Reading', colspan: 3 },
        science: { subject: 'Science', colspan: 3 },
      };
  }
}

export function examRoomSubjects(product: string) {
  switch (product) {
    case 'dsat':
    case 'dpsat':
      return ['rw2', 'math2'];
    case 'sat':
      return ['reading', 'writing', 'rw_average', 'math3', 'math4', 'math_average'];
    case 'act':
    case 'dact':
    default:
      return ['english', 'math', 'reading', 'science'];
  }
}

export function profileSubjects(product: string) {
  switch (product) {
    case 'dsat':
    case 'dpsat':
      return ['rw', 'math'];
    case 'sat':
      return ['rw_average', 'math_average'];
    case 'act':
    case 'dact':
    default:
      return ['english', 'math', 'reading', 'science'];
  }
}

/**
 * Gets the nickname of an SAT subject
 * @param {number} num
 * @return {BasicsSatSubjectsNicknames}
 */
export const getSatSectionNickname = (num: number): BasicsSatSubjectsNicknames => {
  switch (num) {
    default:
    case 1:
      return BasicsSatSubjectsNicknames.reading;
    case 2:
      return BasicsSatSubjectsNicknames.writing;
    case 3:
      return BasicsSatSubjectsNicknames.math3;
    case 4:
      return BasicsSatSubjectsNicknames.math4;
  }
};

/**
 * Gets the label of a subject
 * @param {number | string} key
 * @param {string} product
 * @param {boolean} merged
 * @return {BasicsSatSubjectsLabels | BasicsActSubjectsLabels | BasicDsatSubjectsLabels | BasicDsatSubjectsLabelsMerged}
 */
export const getSectionLabel = (
  key: number | string,
  product: string,
  merged: boolean = false
):
  | BasicsSatSubjectsLabels
  | BasicsActSubjectsLabels
  | BasicDsatSubjectsLabels
  | BasicDsatSubjectsLabelsMerged
  | BasicsDactButtonLabels => {
  if (product === 'sat') {
    switch (key) {
      default:
      case 1:
        return BasicsSatSubjectsLabels.reading;
      case 2:
        return BasicsSatSubjectsLabels.writing;
      case 3:
        return BasicsSatSubjectsLabels.math3;
      case 4:
        return BasicsSatSubjectsLabels.math4;
    }
  } else if (isDsatOrDpsat(product)) {
    if (merged) {
      switch (String(key).toLowerCase().trim()) {
        default:
        case 'rw':
          return BasicDsatSubjectsLabelsMerged.rw;
        case 'math':
          return BasicDsatSubjectsLabelsMerged.math;
      }
    } else {
      switch (+key) {
        default:
        case 1:
          return BasicDsatSubjectsLabels.rw1;
        case 2:
          return BasicDsatSubjectsLabels.rw2;
        case 3:
          return BasicDsatSubjectsLabels.math1;
        case 4:
          return BasicDsatSubjectsLabels.math2;
      }
    }
  } else if (isDact(product)) {
    switch (key.toString().toLowerCase().trim()) {
      default:
      case '1':
      case 'english':
        return BasicsDactButtonLabels.english;
      case '2':
      case 'math':
        return BasicsDactButtonLabels.math;
      case '3':
      case 'reading':
        return BasicsDactButtonLabels.reading;
      case '4':
      case 'science':
        return BasicsDactButtonLabels.science;
    }
  } else {
    switch (+key) {
      default:
      case 1:
        return BasicsActSubjectsLabels.english;
      case 2:
        return BasicsActSubjectsLabels.math;
      case 3:
        return BasicsActSubjectsLabels.reading;
      case 4:
        return BasicsActSubjectsLabels.science;
    }
  }
};

export function getSectionLabelObj(product: string) {
  switch (product) {
    case 'dsat':
    case 'dpsat':
      return {
        rw: 'R&W',
        math: 'Math',
      };
    case 'sat':
      return {
        reading: 'Reading',
        writing: 'Writing',
        rw_average: 'R&W AVG',
        math3: 'Math (No-Calc)',
        math4: 'Math (Calc)',
        math_average: 'Math AVG',
      };
    case 'act':
    case 'dact':
    default:
      return {
        english: 'English',
        math: 'Math',
        reading: 'Reading',
        science: 'Science',
      };
  }
}

export function tsia2Subjects() {
  return ['math', 'elar', 'essay'];
}

export function tsia2SubjectsHeaders() {
  return {
    math: 'Math',
    elar: 'ELAR',
    essay: 'Essay',
  };
}

export function tsia2SubjectsMenu() {
  return {
    math: 'Math',
    elar: 'ELAR',
  };
}

export function getProductDetails(product: string): { name: string; description: string; slug: IBasicProducts } {
  switch (product.toLowerCase().trim()) {
    case 'sat':
    case 'sat-new':
      return {
        name: 'SAT',
        description: "Age appropriate assessment tool based on College Board's suite of assessments",
        slug: BasicProducts.SAT,
      };
    case 'dsat':
      return {
        name: 'Digital SAT',
        description: "Age appropriate assessment tool based on College Board's suite of assessments",
        slug: BasicProducts.DSAT,
      };
    case 'dpsat':
      return {
        name: 'Digital PSAT',
        description: '',
        slug: BasicProducts.DPSAT,
      };
    case 'preact':
      return {
        name: 'PreACT 9/10',
        description: '',
        slug: BasicProducts.PREACT,
      };
    case 'tsi':
      return {
        name: 'TSIA2',
        description: 'A Preparation tool for the TSIA2',
        slug: BasicProducts.TSI,
      };
    case 'qta':
      return {
        name: 'QTA',
        description: 'Quizzes, tests and assignments',
        slug: BasicProducts.QTA,
      };
    case 'react':
      return {
        name: 'ReAct',
        description: 'Practice at your own pace or complete teacher-assigned quizzes.',
        slug: BasicProducts.REACT,
      };
    case 'fsa':
      return {
        name: 'B.E.S.T. EOC',
        description: 'A Preparation tool for the B.E.S.T. End-of-Course Assessments',
        slug: BasicProducts.FSA,
      };
    case 'ksa':
      return {
        name: 'KSA',
        description: 'A Preparation tool for the Kentucky Summative Assessments',
        slug: BasicProducts.KSA,
      };
    case 'gp':
      return {
        name: 'Graduation Planner',
        description: 'Comprehensive college planning tool',
        slug: BasicProducts.GP,
      };
    case 'ssat-ll':
      return {
        name: 'SSAT Middle Level',
        description: '',
        slug: BasicProducts.SSAT_LL,
      };
    case 'ssat-ul':
      return {
        name: 'SSAT Upper Level',
        description: '',
        slug: BasicProducts.SSAT_UL,
      };
    case 'sat-subject':
      return {
        name: 'SAT Subject',
        description: '',
        slug: BasicProducts.SAT_SUBJECT,
      };
    case 'plan':
      return {
        name: 'PreACT 9/10',
        description: '',
        slug: BasicProducts.PLAN,
      };
    case 'dact':
      return {
        name: 'Digital ACT',
        description: "Age appropriate assessment tool based on Digital ACT's suite of assessments",
        slug: BasicProducts.DACT,
      };
    case 'cert':
    case 'act':
    default:
      return {
        name: 'ACT',
        description: "Age appropriate assessment tool based on ACT's suite of assessments",
        slug: BasicProducts.ACT,
      };
  }
}

export function getProductName(product: string): string {
  return getProductDetails(product)?.name ?? 'ACT';
}

export function getActiveProductName(): string {
  return getProductName(getActiveProduct());
}

export function getProductDescription(product: string): string {
  return getProductDetails(product)?.description ?? '';
}

export const getProductSlug = (product: string): IBasicProducts => {
  return getProductDetails(product)?.slug ?? BasicProducts.ACT;
};

// ASSETS
// TODO: the forced prefix is a solution to make ACT content load from the TSIA2 CDN
// TODO: the content will be centralized into one single CDN and this will get fixed automatically
export async function getAssetsUrls(names: string[], forcedPrefix?: string): Promise<{ [key: string]: string }> {
  if (names.length) {
    let assets: { [key: string]: string } = {};

    const storedAssets: IContentAssets = store.getters['contentModule/getAssets'];

    if (storedAssets) {
      const assetsToLoad: string[] = [];

      names.forEach((assetKey) => {
        if (Object.keys(storedAssets).includes(assetKey)) {
          const asset = storedAssets[assetKey];
          const now = Math.floor(new Date().getTime() / 1000);
          if (now > asset.expires_at) {
            store.dispatch('contentModule/removeAsset', assetKey);
            assetsToLoad.push(assetKey);
          } else {
            assets[assetKey] = asset.url;
          }
        } else {
          assetsToLoad.push(assetKey);
        }
      });

      if (assetsToLoad.length) {
        assets = await loadAssets(assetsToLoad, forcedPrefix);
      }
    } else {
      assets = await loadAssets(names, forcedPrefix);
    }

    return assets;
  }
  return {};
}

export async function fetchAssets(assetsKeys: string[], forcedPrefix?: string): Promise<{ [key: string]: string }> {
  if (assetsKeys.length) {
    let assetsUrl = `/${forcedPrefix ?? store.state.productPrefix}content/urls`;

    if (router.currentRoute?.meta?.public) {
      assetsUrl = `/${forcedPrefix ?? store.state.productPrefix}content/public-urls`;
    }

    const { data: loadedAssets } = await api.post<any, any>(assetsUrl, { names: assetsKeys });
    return loadedAssets;
  }
  return {};
}

export const isTestNumD = (testNum: string) => testNum.trim().toUpperCase() === 'D';

export async function loadAssets(assetsKeys: string[], forcedPrefix?: string): Promise<{ [key: string]: string }> {
  const returnedAssets: { [key: string]: string } = {};
  const loadedAssets = await fetchAssets(assetsKeys, forcedPrefix);
  Object.keys(loadedAssets).forEach((assetKey) => {
    const assetUrl = loadedAssets[assetKey];
    const assetToStore = {} as IContentAsset;

    assetToStore.url = assetUrl;
    const params = assetUrl.split('?')[1].split('&');

    if (params && params.length) {
      const expires_at = params.find((param) => param.startsWith('Expires='))?.split('=')[1];
      assetToStore.expires_at = Number(expires_at);
    }
    store.dispatch('contentModule/addAsset', { assetKey, asset: assetToStore });
    returnedAssets[assetKey] = assetUrl;
  });

  return returnedAssets;
}

export const isTablet = (): boolean => {
  const userAgent = navigator.userAgent.toLowerCase();

  // Do not simplify the "ifs" below, they are separated into 3 ifs on purpose

  // @ts-ignore
  if (navigator && navigator.userAgentData && navigator.userAgentData.mobile) {
    return true;
  }

  if (
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent
    )
  ) {
    return true;
  }

  if (window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1) {
    return true;
  }

  return false;
};

export const isCert = (): boolean => {
  const theme = store.getters['theme'];
  const themeName = theme?.name.toLowerCase().trim();
  return themeName === 'cert' || themeName === 'cpa';
};

export const isFsaOrKsa = (productType: string): boolean => {
  return getProductSlug(productType) === 'fsa' || getProductSlug(productType) === 'ksa';
};

export const isFsa = (): boolean => {
  return getProductSlug(getActiveProduct()) === 'fsa';
};

export const isKsa = (): boolean => {
  return getProductSlug(getActiveProduct()) === 'ksa';
};

export const isReact = (productType: string): boolean => {
  return getProductSlug(productType) === 'react';
};

export const isActiveReact = (): boolean => {
  return isReact(getActiveProduct());
};

export const convertToCSV = (objArray: any) => {
  const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  let str = '';

  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (const index in array[i]) {
      if (line != '') line += ';';

      line += array[i][index];
    }

    str += line + '\r\n';
  }

  return str;
};

export const triggerDownloadFile = (fileName: string, objUrl: string) => {
  const link = document.createElement('a');
  link.setAttribute('href', objUrl);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getReportFileName = (reportName: string, extension: string) => {
  const account = store.getters['authModule/account'] as IUser;
  const name = account?.name.trim().toLowerCase().replace(' ', '-');
  const date = DateTime.now().toMillis();

  return `${getActiveProduct()}-${reportName}-report-${name}-${date}.${extension}`;
};

export const checkIsOpenTextQuestion = (choice_obj: string, choice_fig: string): boolean => {
  return (choice_obj === 'null' || !choice_obj) && (choice_fig === 'null' || !choice_fig);
};

export const formatDate = (date: string, to: 'datetime' | 'date' = 'datetime', format: 'iso' | 'sql' = 'iso') => {
  let ret;

  switch (format) {
    case 'sql':
      ret = DateTime.fromSQL(date);
      break;
    case 'iso':
    default:
      ret = DateTime.fromISO(date);
      break;
  }

  switch (to) {
    case 'date':
      return ret.toLocaleString(DateTime.DATE_MED) as string;
    case 'datetime':
    default:
      return ret.toLocaleString(DateTime.DATETIME_MED) as string;
  }
};
